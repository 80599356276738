@import "../main/abstracts/index";

.section--embed-social-custom{

    body[data-template="home"] .page__main > &{

        header{
            margin-bottom: 5px;
        }
    }

    header{

        h2{

            .subheadline{
                @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 18px, 20px);
                font-weight: $font__weight--normal;
                color: $color__black;
                margin-left: 35px;
            }

            @include media-breakpoint-down(md) {

                .subheadline{
                    margin-left: 0;
                    margin-top: 6px;
                }

                span{
                    display: block;
                }
            }
        }
    }
}

